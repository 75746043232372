import React, { useState, useEffect } from 'react'
import { Link, navigate } from 'gatsby'
import * as st from '~/assets/styl/Menu.module.styl'
import logoUnicred from '~/assets/svg/svg-logo-dark.svg'
import AutoComplete from './AutoComplete'
import { useContext } from '~/hooks'
import { useApi } from '~/siteApi'
import cn from 'classnames'
import api from '../siteApi/default'
import * as homeStyles from '~/assets/styl/Home.module.styl'
import { Placeholder } from 'gatsby-plugin-image'

interface Props {
  location: Location
}

const Menu = ({ location }: Props) => {
  const [navbarOpen, setNavbarOpen] = useState(false)
  const [searchMobile, setSearchMobile] = useState(false)
  const [openSubmenu, setOpenSubmenu] = useState(-1)
  const [menu, setMenu] = useState([])
  const [newMenu, setNewMenu] = useState([])
  const [visible, setVisible] = useState(false)
  const [titleBtn, setTitleBtn] = useState('Encontre sua cooperativa')
  const handleOpenSubmenu = (index: number) => {
    setOpenSubmenu(index === openSubmenu ? -1 : index)
  }

  const toggleAutoComplete = () => {
    setVisible(!visible)
  }

  useEffect(() => {
    const hide = () => setNavbarOpen(false)
    window.addEventListener('click', hide)
    return () => window.removeEventListener('click', hide)
  }, [])

  useEffect(() => {
    if (location.pathname.startsWith('/unicred-mato-grosso')) {
      const newMenu = menu.filter(({ benefit }) => {
        return benefit.name !== 'INFORMAÇÕES SISTÊMICAS'
      })

      setMenu(newMenu)
    }
  }, [menu.length > 0])

  const handleToggle = () => {
    setNavbarOpen(!navbarOpen)
    setOpenSubmenu(-1)
  }
  const handleClose = () => {
    setNavbarOpen(false)
  }
  const toggleSearch = () => {
    setSearchMobile(!searchMobile)
  }

  const { setLoading } = useContext()

  const [placeholder, setPlaceholder] = useState('')
  const [pathHome, setPathHome] = useState('')

  useEffect(() => {
    setLoading(true)
    const url = location.pathname.split('/')
    setPathHome(url[1])
    const promises = []

    promises.push(
      new Promise((resolve, reject) =>
        api('get', 'page-benefits/' + url[1]).then(({ benefits }) => {
          const promises = []
          for (const benefit of benefits) {
            promises.push(
              api(
                'get',
                'page-deliverables/' + url[1] + '/' + benefit.slug
              ).then(({ department, benefit, deliverables }) => ({
                department,
                benefit,
                deliverables,
              }))
            )
          }
          Promise.all(promises)
            .then((data) => {
              resolve(
                data.reduce(
                  (arr, curr) => (Array.isArray(curr) ? curr.concat(arr) : arr),
                  []
                )
              )
              setPlaceholder(data[0].department.name)
              setMenu(data)
              setLoading(false)
            })
            .catch((e) => {
              reject(e)
              navigate('/404')
            })
        })
      )
    )
  }, [location.pathname])

  useEffect(() => {
    setSearchMobile(false)
  }, [location])

  const ApiMedicalPlan = useApi(
    {
      primer: {},
    },
    'page-departments'
  )

  const styles = {
    button: {
      marginRight: '120px',
      background:
        '#FFFFFF url(../svg/svg-ico-search.svg) 14px center no-repeat',
      border: 'none',
      borderRadius: '24px',
      boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.16)',
      padding: '14px 13px 13px 53px',
      fontSize: '1.6em',
      lineHeight: '1.5em',
      width: 'calc(100% - 115px)',
      justifySelf: 'flex-end',
    },
  }

  return menu ? (
    <header className={cn(st.core, searchMobile && st.active)}>
      <Link
        to={`/${pathHome}`}
        title="Ir para página inicial"
        className={searchMobile && st.active}
      >
        <img src={logoUnicred} alt="Logo da Unicred" />
      </Link>
      <button
        className={cn(st.btnOpenSearch, searchMobile && st.active)}
        onClick={toggleSearch}
      >
        Abrir pesquisa
      </button>
      <div className={st.containerCoop}>
        <span>Sua cooperativa: </span>
        <button className={st.btnSelect} onClick={toggleAutoComplete}>
          <span>{placeholder}</span>
        </button>
        {visible && <AutoComplete placeholder={placeholder} />}
      </div>
      <nav onClick={(e) => e.stopPropagation()}>
        <button className={searchMobile && st.active} onClick={handleToggle}>
          Menu<span></span>
        </button>
        <ul className={navbarOpen && st.active}>
          <li>
            <button onClick={handleClose} className={st.btnClose}>
              Fechar
            </button>
          </li>
          <li>
            <Link onClick={handleClose} to="/">
              Home
            </Link>
          </li>
          {menu.map(({ benefit, deliverables, department }, index) => (
            <li key={index}>
              <div>
                <Link onClick={() => handleOpenSubmenu(index)} to={''}>
                  {benefit.name}
                </Link>
                <button
                  className={cn(openSubmenu === index && st.active)}
                  onClick={() => handleOpenSubmenu(index)}
                ></button>
              </div>
              <ul className={cn(openSubmenu === index && st.active)}>
                {deliverables.map(({ slug, title }, index) => (
                  <li key={index}>
                    <Link
                      onClick={handleClose}
                      to={`/${department.slug}/${benefit.slug}/${slug}`}
                    >
                      {title.replace('/n', '')}
                    </Link>
                  </li>
                ))}
              </ul>
            </li>
          ))}
          {ApiMedicalPlan && (
            <li>
              <p className="text-center">
                <strong>{ApiMedicalPlan.primer.text}</strong>
              </p>
              <a
                href={ApiMedicalPlan.primer.file}
                className="btn color-white bkg-green text-center"
                target="_blank"
                rel="noreferrer"
              >
                Faça aqui o download
              </a>
            </li>
          )}
        </ul>
      </nav>
    </header>
  ) : (
    <></>
  )
}

export default Menu

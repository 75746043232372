import React, { useEffect, useState } from 'react'
import * as st from '~/assets/styl/Banner.module.styl'
import { useMediaQuery } from 'react-responsive'
import { Link } from '~/components'
import * as stCl from '~/assets/styl/ContainerList.module.styl'
import cn from 'classnames'

interface Props {
  title: string
  text?: string
  img?: string
  imgMobile: string
  ctaList?: Array<{
    name: string
    slug: string
    color: string
  }>
}

const Banner = ({ title, text, img, imgMobile, ctaList }: Props) => {
  const isMobile = useMediaQuery({ query: '(max-width: 820px)' })
  const noBreak = useMediaQuery({ query: '(max-width: 767px)' })
  const [newCtaList, setNewCtaList] = useState([])

  const partTitle = title.split(' ')

  useEffect(() => {
    if (title === 'Unicred Mato Grosso') {
      const newCta = ctaList.filter((cta) => {
        return cta.name !== 'INFORMAÇÕES SISTÊMICAS'
      })

      setNewCtaList(newCta)
    } else {
      setNewCtaList(ctaList)
    }
  }, [])

  return (
    <div
      className={st.core}
      style={{
        background: `url(${
          isMobile === true ? imgMobile : img
        }) top center no-repeat`,
      }}
    >
      <section className="container">
        {/* {noBreak === false ? <h1>{nl2br(title)}</h1> : <h1>{title}</h1>} */}
        <h1 className="title large-size color-white">
          {partTitle[0]}
          <br /> {partTitle.slice(1).join(' ')}
        </h1>
        {/* {noBreak === false ? <p>{subtitle}</p> : ''} */}
        <p className="color-white">{text}</p>

        {ctaList && (
          <ul className={cn(stCl.list, stCl.pageHome)}>
            {newCtaList
              .filter(({ name }) => name !== 'Parcerias')
              .map(({ name, slug, color }, index) => (
                <li
                  key={index}
                  className={cn(stCl.customBkg, {
                    [stCl.newCustomBkg]: title === 'Unicred Mato Grosso',
                  })}
                  style={{ color: `${color}` }}
                >
                  <p>
                    <span>Saiba mais</span>
                  </p>
                  <Link href={`/${slug}`}>{name}</Link>
                </li>
              ))}
          </ul>
        )}
      </section>
      <Link href="?#cartilha">Role para baixo</Link>
    </div>
  )
}

export default Banner

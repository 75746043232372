// AutoComplete component

import React, { useState, useEffect } from 'react'
import { Link } from '~/components'
import { useApi } from '~/siteApi'
import * as st from '~/assets/styl/Autocomplete.module.styl'
import { navigate } from 'gatsby'
import cn from 'classnames'

interface Props {
  className?: string
  placeholder?: string
  setTitleBtn?: React.Dispatch<React.SetStateAction<string>>
  setVisible?: React.Dispatch<React.SetStateAction<boolean>>
  label?: string
}

const AutoComplete = ({
  className,
  placeholder,
  setTitleBtn,
  setVisible,
  label,
}: Props) => {
  const { departments } = useApi(
    {
      departments: [],
    },
    'page-departments'
  )

  const [inputSearch, setInputSearch] = useState('')
  const [filterSearch, setFilterSearch] = useState(departments)

  const handleFilter = (e) => {
    setInputSearch(e.target.value)

    const newFilter = departments.filter(({ name }) =>
      new RegExp('(' + e.target.value + ')', 'gi').test(name)
    )

    setFilterSearch(newFilter)
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault()
      navigate(`/${inputSearch.replace(/ /g, '-')}`)
      setInputSearch('')
    }
  }

  return (
    <div className={cn(st.core, className)}>
      {label && <label htmlFor="input-search">{label}</label>}
      {/*<input*/}
      {/*  type="text"*/}
      {/*  id="input-search"*/}
      {/*  value={inputSearch}*/}
      {/*  onChange={handleFilter}*/}
      {/*  placeholder={placeholder}*/}
      {/*/>*/}
      <div className={departments.length > 0 && st.active}>
        <ul>
          {departments.map(({ name, slug }, index) => (
            <li key={index}>
              <Link
                href={`/${slug}`}
                // onClick={() => {
                //   setTitleBtn(name)
                //   setInputSearch('')
                //   setVisible(false)
                // }}
              >
                {name}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}

export default AutoComplete

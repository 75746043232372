import React from 'react'
import * as st from '~/assets/styl/DefaultContainer.module.styl'
import cn from 'classnames'
import nl2br from 'react-nl2br'

interface Props {
  title: string
  slug: string
  textCta: string
}

const DefaultContainer = ({ title, textCta, slug }: Props) => {
  return (
    <section className={cn('container', st.core)} id="cartilha">
      <h2 className="title medium-size">{nl2br(title)}</h2>
      <a href={slug} target="_blank" rel="noreferrer" className="btn bkg-green">
        {textCta}
      </a>
    </section>
  )
}

export default DefaultContainer

import React, { createContext, useState, useEffect } from 'react'
import { Loading } from '~/components'
import { TransitionProvider, TransitionViews } from 'gatsby-plugin-transitions'
import { PageProps } from 'gatsby'

interface Context {
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
}

export const context = createContext<Context>(null)

const layout = (loadingDefault: boolean, { children, location }: PageProps) => {
  const [loading, setLoading] = useState(loadingDefault)
  const [loadingActive, setLoadingActive] = useState(loadingDefault)

  const [enterOpacity, setEnterOpacity] = useState(0.9999)
  useEffect(() => {
    setEnterOpacity(0)
  }, [])

  return (
    <>
      {loading && <Loading active={loadingActive} />}
      <context.Provider
        value={{
          setLoading: (value: boolean) => {
            if (value) {
              setLoading(true)
              setLoadingActive(true)
            } else {
              setLoadingActive(false)
              setTimeout(() => setLoading(false), 500)
            }
          },
        }}
      >
        <TransitionProvider
          location={location}
          mode="immediate"
          enter={{
            opacity: enterOpacity,
          }}
          usual={{
            opacity: 1,
          }}
          leave={{
            opacity: 0,
            config: {
              duration: 200,
            },
          }}
        >
          <TransitionViews>{children}</TransitionViews>
        </TransitionProvider>
      </context.Provider>
    </>
  )
}

export default (props: PageProps) => {
  const context = props.pageContext as { [key: string]: never }
  if (context?.layout === '404') return layout(true, props)
  return layout(false, props)
}

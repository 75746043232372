import React, { useState, useEffect } from 'react'
import { Link } from 'gatsby'
import * as st from '~/assets/styl/Footer.module.styl'
import logoUnicred from '~/assets/svg/svg-logo.svg'
import logoUnidos from '~/assets/svg/svg-logo-unidos.svg'
import logo from '~/assets/svg/logo.svg'

interface Props {
  location: Location
}

const Footer = ({ location }: Props) => {
  const [url, setUrl] = useState('')

  useEffect(() => {
    const newUrl = location.pathname.split('/')
    setUrl(newUrl[1])
  })

  return (
    <footer className={st.core}>
      <div className="container clear-fix">
        <img src={logo} alt="" className={st.logo} />
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to={`/${url}/informacoes-sistemicas/`}>
              Informações sistêmicas
            </Link>
          </li>
          <li>
            <Link to={`/${url}/informacoes-extras/`}>Informações extras</Link>
          </li>
          <li>
            <Link to={`/${url}/informacoes-regionais/`}>
              Informações regionais
            </Link>
          </li>
        </ul>
        <img src={logoUnicred} alt="Logo da Unicred" />
      </div>
      <a
        href="https://www.tiki.com.br/"
        title="Site da Tiki Web"
        target="_blank"
        rel="noreferrer"
      >
        Tiki
      </a>
    </footer>
  )
}

export default Footer
